<template>
  <b-sidebar
    :id="'add-new-user-sidebar' && expanded ? 'mein-expanded-view' : ' ' "
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="
      $refs.refFormObserver && $refs.refFormObserver.flags.dirty
    "
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template
      #default="{ hide }"
    >
      <!-- Header -->
      <div
        class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 wrapped-ul"
      >
        <h5 class="mb-0 role-title">
          {{ title }}
        </h5>
        <div>
          <feather-icon
            v-if="isReadonly && $can('edit', 'role')"
            class="ml-1 cursor-pointer"
            icon="EditIcon"
            size="16"
            @click="editTab(value)"
          />
          <feather-icon
            :target="`expand-minimize-view`"
            class="ml-1 cursor-pointer"
            :icon="expanded ? 'MinimizeIcon' : 'MaximizeIcon'"
            size="16"
            @click="sideResize"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 add-edit-sidebar-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- ID -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="ID"
                rules="required"
              >
                <b-form-group
                  v-if="isReadonly"
                  label="Role ID"
                >
                  <b-list-group>
                    <b-list-group-item class="input-wrap">
                      {{ form.roleHandle }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  label="Role ID"
                  label-for="id"
                  class="required-field"
                >
                  <b-form-input
                    id="id"
                    v-model="form.roleHandle"
                    :state="getValidationState(validationContext)"
                    :plaintext="isReadonly"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Full Name -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Role Name"
                rules="required"
              >
                <b-form-group
                  v-if="isReadonly"
                  label="Role Name"
                >
                  <b-list-group>
                    <b-list-group-item class="input-wrap">
                      {{ form.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                  label-for="name"
                  class="first-required-field"
                >
                  <label
                    for="name"
                    class="label-edit"
                  >Role Name (<span>*</span> <label>required</label> )</label>
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :plaintext="isReadonly"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Description -->
          <b-row>
            <b-col md="12">
              <validation-provider
                name="Description"
              >
                <label
                  class="label-edit"
                >Description</label>
                <Quill-Editor
                  :description.sync="form.description"
                  :is-readonly.sync="disabled"
                  name="editRole"
                  @assign="changeDesc"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Filled By -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Filled By"
              >
                <!-- Details -->
                <b-form-group
                  v-if="isReadonly"
                  label="Filled By"
                >
                  <b-overlay
                    :show="state"
                    rounded="lg"
                    opacity="0.6"
                  >
                    <template v-slot:overlay>
                      <div class="d-flex align-items-center">
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <b-spinner
                          type="grow"
                          variant="dark"
                        />
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <!-- We add an SR only text for screen readers -->
                        <span class="sr-only">Please wait...</span>
                      </div>
                    </template>
                    <b-list-group v-if="form.filledBy && form.filledBy.length !== 0">
                      <b-list-group-item
                        v-for="option in form.filledBy"
                        :key="option.id"
                        class="wrapped hyperlink-underlined cursor-pointer"
                        @click="gotoUser(option)"
                      >
                        {{ option.name }}
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group
                      v-else
                      class="text-danger"
                    >
                      <b-list-group-item>
                        Not Assigned
                      </b-list-group-item>
                    </b-list-group>
                  </b-overlay>
                </b-form-group>

                <!-- Form -->
                <b-form-group
                  v-if="!isReadonly"
                  label="Filled By"
                  label-for="filled-by"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.filledByIds"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="isReadonly || currentRouteName === 'my-role-list'"
                    multiple
                    input-id="filled-by"
                    class="wrapped-li force-bg"
                    append-to-body
                    :calculate-position="withPopper"
                    @change="onFilledByChange($event)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Reports To -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="ParentId"
              >
                <!-- Details -->
                <b-form-group
                  v-if="isReadonly"
                  label="Reports To"
                >
                  <b-list-group v-if="form.reportsTo">
                    <b-list-group-item
                      class="wrapped hyperlink-underlined cursor-pointer"
                      @click="gotoRole(form.reportsTo)"
                    >
                      {{ form.reportsTo.roleHandle }}
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group
                    v-else
                    class="text-danger"
                  >
                    <b-list-group-item>
                      Not Assigned
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- Form -->
                <b-form-group
                  v-if="!isReadonly"
                  label="Reports To"
                  label-for="reportsToId"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.reportsToId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :disabled="isReadonly"
                    input-id="reportsTo"
                    class="wrapped-li"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Assigned Processes -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <b-form-group
                v-if="isReadonly"
                label="Processes for this Role"
                label-for="assigned-processes"
              >
                <div v-if="isProcessLoading">
                  Loading...</div>
                <b-table
                  v-else
                  show-empty
                  hover
                  empty-text="No matching records found"
                  :items="processArray"
                  mb-0
                  :fields="fields"
                  table-variant="light-secondary"
                  class="custom-table"
                  fixed
                  small
                  @row-clicked="gotoProcess"
                >
                  <template #cell(name)="data">
                    <span
                      v-if="data.item.name === 'Not Assigned'"
                      style="color: red"
                    >
                      {{ data.item.name }}
                    </span>
                    <span
                      v-else
                      style="overflow-wrap:anywhere;"
                    >
                      {{ data.item.processHandle + ': '+ data.item.name }}
                    </span>
                  </template>
                  <template #cell(state)="data">
                    <div class="">
                      <b-badge
                        v-if="data.item.name !== 'Not Assigned'"
                        pill
                        :variant="data.item.owned && data.item.assigned ? 'primary' : data.item.assigned ? 'dark' : 'success' "
                      >
                        {{
                          data.item.owned && data.item.assigned ?
                            'Owner & Assignee' : data.item.assigned ? "Assignee only" : 'Owner only'
                        }}
                      </b-badge>
                    </div>
                  </template>
                </b-table>
              </b-form-group>
              <validation-provider
                #default="validationContext"
                name="Processes for this Role"
              >
                <b-form-group
                  v-if="!isReadonly"
                  label="Processes for this Role"
                  label-for="assigned-processes"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="processArray"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="processOptions"
                    :reduce="(val) => val"
                    :disabled="isReadonly"
                    multiple
                    input-id="assigned-processes"
                    class="custom-vSelect wrapped-li"
                    append-to-body
                    :calculate-position="withPopper"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="!isReadonly"
                >
                  <b-table
                    show-empty
                    hover
                    empty-text="No matching records found"
                    :items="processArray"
                    mb-0
                    :fields="fields"
                    table-variant="light-secondary"
                    class="custom-table"
                    fixed
                    small
                  >
                    <!-- @row-contextmenu="handleContextMenuEvent" -->
                    <template #cell(name)="data">
                      <span
                        v-if="data.item.name === 'Not Assigned'"
                      >
                        {{ data.item.name }}
                      </span>
                      <span
                        v-else
                        style="overflow-wrap:anywhere;"
                      >
                        {{ data.item.processHandle + ': '+ data.item.name }}
                      </span>
                      <!-- <vue-context
                        :ref="data.item.id"
                        :close-on-click="false"
                        style="padding:0px"
                      > -->
                      <b-row style="width:100%;padding-top:15px;">
                        <b-col
                          md="6"
                          sm="6"
                        >
                          <b-form-checkbox
                            v-model="data.item.owned"
                            :value="processState[0]"
                            name="OWNED"
                            inline
                          >
                            {{ processState[0] }}
                          </b-form-checkbox>
                        </b-col>
                        <b-col
                          md="6"
                          sm="6"
                        >
                          <b-form-checkbox
                            v-model="data.item.assigned"
                            :value="processState[1]"
                            name="Assigned"
                            inline
                          >
                            {{ processState[1] }}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <!-- </vue-context> -->
                    </template>
                    <template #cell(state)="data">
                      <b-row>
                        <b-col md="12">
                          <b-badge
                            v-if="data.item.name !== 'Not Assigned'"
                            class="mb-1"
                            pill
                            :variant="!data.item.owned && !data.item.assigned ? 'danger' : data.item.owned && data.item.assigned ? 'primary' : data.item.assigned ? 'dark' : 'success' "
                          >
                            {{
                              data.item.owned && data.item.assigned ?
                                'Owner & Assignee' : data.item.assigned ? "Assignee only" : !data.item.owned && !data.item.assigned ? 'Not Assigned' : 'Owner only'
                            }}
                          </b-badge>
                        </b-col>
                        <b-col md="12">
                          <b-button
                            v-if="data.item.owned || data.item.assigned"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon rounded-circle deleteIcon-edit"
                            @click="data.item.owned = false, data.item.assigned = false"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div
            class="mt-2 fixed-footer-form-actions"
            :class="{'d-flex' : isReadonly || !isEditing}"
          >
            <div v-if="!isReadonly">
              <div
                v-if="isEditing"
                class="d-flex justify-content-between"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
                <div>
                  <b-button
                    v-for="el in btns"
                    :key="el"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="el === 'Save' ?'outline-primary' : 'primary'"
                    :class="{'mr-2 ': el === 'Save'}"
                    type="submit"
                    :disabled="isLoading"
                    @click="submitState = el"
                  >
                    {{ el }}
                  </b-button>
                </div>
              </div>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="isLoading"
              >
                Add
              </b-button>
            </div>
            <b-button
              v-if="isReadonly || !isEditing"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BListGroup,
  BListGroupItem,
  BBadge,
  BTable,
  // BCol,
  BFormCheckbox,
  BOverlay, BSpinner,
  BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VueContext from 'vue-context'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { createPopper } from '@popperjs/core'
import countries from '@/@fake-db/data/other/countries'
// import ability from '@/libs/acl/ability'
import QuillEditor from '@/views/shared/QuillEditor.vue'

export default {
  components: {
    // VueContext,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BListGroup,
    BListGroupItem,
    BBadge,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    BOverlay,
    BSpinner,
    QuillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isReadonly: {
      type: Boolean,
    },
    state: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => this.getDefaultForm(),
    },
    editTab: {
      type: Function,
      default: () => this.editTab(this.value),
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      form: this.getDefaultForm(),
      btns: ['Save', 'Save & Close'],
      submitState: '',
      processArray: [],
      fields: [
        // { key: 'ID' },
        { key: 'Name', label: 'Process Name', class: 'table-th-1' },
        {
          key: 'State', label: 'OWNER/ASSIGNEE', thStyle: { width: '40% !important' }, class: 'table-th-2',
        },
      ],
      allProcess: [],
      allProcessIds: [],
      processState: [
        'OWNED', 'ASSIGNED',
      ],
      disabled: this.isReadonly,
      expanded: false,
      isProcessLoading: false,
    }
  },
  computed: {
    ...mapState('app', ['isLoading']),
    ...mapState('user', ['users', 'allUsers', 'currentUser']),
    ...mapState('process', ['processes', 'allProcesses']),
    ...mapState('role', ['roles', 'role', 'allRoles']),
    isEditing() {
      return !!this.value
    },
    title() {
      if (this.isReadonly) {
        return 'Role Details'
      }
      return this.isEditing ? 'Edit Role' : 'Add A New Role'
    },
    userOptions() {
      return this.allUsers.nodes.map(item => ({
        label: item.name,
        value: item.id,
      }))
    },
    currentRouteName() {
      return this.$route.name
    },
    processOptions() {
      return this.allProcesses.nodes.map(item => ({
        label: item.name,
        value: item.id,
        owned: 'OWNED',
        assigned: 'ASSIGNED',
        ...item,
      }))
    },
    roleOptions() {
      if (this.isEditing) {
        return this.allRoles.nodes
          .filter(item => item.id !== this.value.id)
          .map(item => ({
            label: item.roleHandle,
            value: item.id,
            ...item,
          }))
      }
      return this.allRoles.nodes.map(item => ({
        label: item.roleHandle,
        value: item.id,
        ...item,
      }))
    },
  },
  watch: {
    async value(value) {
      this.isProcessLoading = true
      if (value) {
        this.form = {
          ...value,
          ...this.role,
          ...(value.filledBy
            ? {
              filledByIds: value.filledBy.map(user => user.id),
            }
            : {}),
          __typename: undefined,
        }
        this.roleItems()
      }
      if (this.value && this.value.id) {
        await this.fetchData()
        await this.getRole({ id: this.value.id, relations: ['assignedProcesses', 'process'] })
      }
      if (value === null && !this.isReadonly) {
        this.processArray = []
        this.form.description = null
      }
      this.isProcessLoading = false
    },
    role() {
      // this.processArray = []
      this.form = { ...this.form, ...this.value, ...this.role }
      this.roleItems()
    },
    isReadonly() {
      this.disabled = this.isReadonly
    },
  },
  updated() {
    this.$emit('reset-state')
    if (!this.isReadonly) {
      this.fetchUsersIfNotLoaded()
      this.fetchRolesIfNotLoaded()
      this.fetchProcessesIfNotLoaded()
    }
  },
  methods: {
    ...mapActions('user', ['fetchCurrentUserIfNotLoaded', 'fetchUsersIfNotLoaded']),
    ...mapActions('process', [
      'fetchProcessesIfNotLoaded',
      // 'getCurrentUserProcesses',
    ]),
    ...mapActions('role', ['fetchRolesIfNotLoaded', 'getRole']),
    gotoProcess(item) {
      const route = this.$router.resolve({ path: '/processes', query: { q: item.processHandle, qd: item.processHandle, compact: true } })
      window.open(route.href, '_blank')
    },
    gotoUser(item) {
      const route = this.$router.resolve({ path: '/people', query: { q: item.name, qd: `id=${item.id}`, compact: true } })
      window.open(route.href, '_blank')
    },
    gotoRole(item) {
      const route = this.$router.resolve({ path: '/roles', query: { q: item.roleHandle, qd: `id=${item.id}`, compact: true } })
      window.open(route.href, '_blank')
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault()
      this.$refs[`${item.id}`].open()
    },
    fetchData() {
      this.fetchUsersIfNotLoaded()
      this.fetchRolesIfNotLoaded()
      this.fetchProcessesIfNotLoaded()
    },
    sideResize() {
      this.expanded = !this.expanded
    },
    resetForm() {
      this.$emit('update:value', this.getDefaultForm())
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      // this.form = this.getDefaultForm()
    },
    openProcess(option) {
      this.$emit('open-process', option)
    },
    changeDesc(value) {
      this.form.description = value
    },
    onSubmit() {
      this.ownerAndAssigneeArray()
      const form = {}
      const {
        id, name, roleHandle, description, reportsToId, filledByIds, assignedProcessIds, process,
      } = this.form
      form.id = id
      form.name = name
      form.roleHandle = roleHandle
      form.description = description
      form.reportsToId = reportsToId
      form.filledByIds = filledByIds
      form.assignedProcessIds = assignedProcessIds
      form.processIds = process

      if (this.currentRouteName === 'my-role-list') {
        this.fetchCurrentUserIfNotLoaded().then(() => {
          const { id: currentUserId } = this.currentUser
          form.filledByIds = [currentUserId]
        })
      }
      const action = this.isEditing
        ? this.$store.dispatch('role/updateRole', form)
        : this.$store.dispatch('role/addRole', form)

      action.then(() => {
        if (this.submitState === 'Save') {
          this.$emit('update:is-sidebar-active', true)
          this.$emit('update:is-readonly', true)
        } else {
          this.$emit('update:is-sidebar-active', false)
        }
      }).then(() => {
        this.$store.dispatch('user/getUsers', {
          offset: 0,
          limit: 1000,
        })

        this.$emit('update:profile')

        if (this.currentRouteName === 'my-role-list') {
          this.$emit('added-new-role')
        }
      })
    },
    ownerAndAssigneeArray() {
      const process = []
      const assignedProcessIds = []
      this.processArray.forEach(el => {
        if (el.owned) {
          process.push(el.id)
        }
        if (el.assigned) {
          assignedProcessIds.push(el.id)
        }
      })
      this.form.assignedProcessIds = assignedProcessIds
      this.form.process = process
    },
    getDefaultForm() {
      return {
        name: '',
        roleHandle: '',
        description: '',
        reportsToId: null,
        filledByIds: [],
        formData: false,
        assignedProcessIds: null,
        assignedProcess: null,
        process: null,
        allProcesses: true,
      }
    },
    listGroupItems(ids, array) {
      if (ids && ids[0] != null && ids.length > 0) {
        const items = ids.map(id => array.find(el => el.value === id))
        return items
      }
      const item = [{ label: 'Not Assigned', value: `${Math.random()}` }]
      return item
    },
    roleItems() {
      this.processArray = []
      const role = this.form
      if (role.assignedProcesses && role.assignedProcesses.length === 0 && role.process && role.process.length === 0) {
        const item = [{ name: 'Not Assigned', value: `${Math.random()}` }]
        return item
      }
      const allProcesses = this.combineArrays(role.assignedProcesses, role.process)
      const ids = []
      allProcesses.forEach(item => (
        ids.includes(item.id) ? null : ids.push(item.id)
      ))
      const processes = allProcesses
      processes.forEach((element, index) => {
        const owned = role.process.find(pr => pr.id === element.id)
        const assigned = role.assignedProcesses.find(aPr => aPr.id === element.id)
        if (owned) {
          processes[index].owned = 'OWNED'
        }
        if (assigned) {
          processes[index].assigned = 'ASSIGNED'
        }
      })
      this.processArray = processes.map(item => ({
        label: item.name,
        value: item.id,
        ...item,
      }))
      return processes
    },
    combineArrays(arr1, arr2) {
      if (arr1 && arr2) {
        const allArr = []
        arr1.forEach(el => {
          const arr2El = arr2.find(item => item.id === el.id)
          if (arr2El) {
            allArr.push({ ...el, ...arr2El })
          } else {
            allArr.push(el)
          }
        })
        arr2.forEach(el => {
          const arr2El = allArr.find(item => item.id === el.id)
          if (!arr2El) {
            allArr.push(el)
          }
        })
        return allArr
      }
      if (!arr1 && !arr2) {
        return []
      } if (!arr1) {
        return arr2
      }
      return arr1
    },
    openUser(value) {
      if (value.label !== 'Not Assigned') {
        this.$emit('open-user', value.value)
      }
    },
    openRole(value) {
      if (value.label !== 'Not Assigned') {
        this.$emit('open-role', value)
      }
    },
    displayProcess(value) {
      if (value.label !== 'Not Assigned') {
        this.$emit('open-process', value)
      }
    },
    removeProcess(value) {
      this.processArray = this.processArray.filter(el => el.id !== value.id)
    },
    withPopper(dropdownList, component, { width }) {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-select";

#mein-expanded-view{
  width: 80%!important;
}

#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.text-area{
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 14px;
}

.required-field{
  label::after{
    content: '*';
    color: #ea5455;
    font-weight: bold;
    font-size: larger;
    margin-left: 2px;
  }
}

.first-required-field{
  span{
    color: #ea5455;
    font-weight: bold;
    font-size: larger;
  }
}

.form-group {
  label{
    font-size: 1rem;
  }
}

.table-col{
  max-width: 10px;
  text-align: center;
}

.custom-table {
  color: black;
    > td > .table-col{
    max-width: 10px;
  }
  td{
    vertical-align: top!important;
  }
  thead{
    .table-th-1{
      border-width: 0px!important;
      border-top-left-radius: 0.357rem;
      border-bottom-left-radius: 0.357rem;
    }
    .table-th-2{
        border-width: 0px!important;
        border-top-right-radius: 0.357rem;
        border-bottom-right-radius: 0.357rem;
    }
  }
}

.drop-menu{
  ul{
    height: 150px;
  }
  button{
    padding:5px 20px;
  }
}

.custom-vSelect{
  span{
    display: none;
  }
}

.fixed-sidebar-header{
  position: fixed;
  width: 100%;
  z-index: 10000;
}

.add-edit-sidebar-form{
  margin-top: 40px;
  margin-bottom: 52px;
  padding-top: 12px;
}

.fixed-footer-form-actions{
  position:fixed;
  bottom: 0;
  padding: 16px 58px 16px 0;
  width: 100%;
  background-color: white;
  z-index: 10000;
}
.drop-btn-edit{
  padding:5px 20px;
}
.input-wrap{
    overflow-wrap: break-word;
}
  .truncate{
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
     }
.role-title{
  @extend .truncate;
  max-width: 70%;
}
.table-th-2{
  text-align: center;
}
.force-bg{
  span{
    background-color: #193a9a !important;
  }
}
</style>
